.blog.blog-s{
	background: #fff;
	padding:20px;
}
.blog .blog-top{
	padding: 10.5px 20px;
	display:flex;
	background: rgba(255,255,255,0.9);
	z-index:999;
	position: relative;
}
.blog .blog-top.sticky{
	position: sticky;
	top:0;
	box-shadow: 0 3px 7px rgb(0 0 0 / 19%), 0 3px 31px rgb(0 0 0 / 23%);
}
.blog .blog-top .btn-secondary{
	width:94px;
	height:39px;
	padding: 7px 0px !important;
	text-align:center;
}
.blog .blog-top .icon{
	font-size:25px;
	padding:0 10px;
	color: #B1A1A1;
}
.blog .blog-top .icon:last-child{
	padding-right:0;
}
.blog .btn-back{
	color: #0AA09F !important;
    font-size: 24px !important;
    margin-top: 4px;
    margin-bottom: -4px;
    margin-right: 10px;
    padding: 0 !important;
    background: transparent !important;
}
.blog .search-container{
	position: relative;
	width: calc(100% - 133px);
    margin-right: 15px;
}
.blog .search{
	width: 100%;
	height:39px;
	border:0;
	border-radius: 30px;
	padding: 6px 20px;
	padding-left: 60px;
	font-size: 14px;
	font-weight: 400;
	border:1px solid #E6E2E2;
}
.blog .search::placeholder {
	color: #CBC0C0;
}
.blog .search:focus{
	outline:0;
}
.blog .search-icon{
	position:absolute;
	bottom:2px;
	left:20px;
}
.blog .search-icon i{
	color: #0AA09F;
	font-size:23px;
}
.blog .react-tabs{
	padding: 20px;
	clear:both;
}
.blog .tab-scroll{
	overflow-y:auto;
}
.blog .blog-single-top{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	padding: 10px;
}
.blog .blog-single-top-right{
	float:right;
}
.blog .blog-single-top-right i{
	margin: 0 10px;
}
.blog .blog-single-top-right i:last-child{
	margin-right: 0;
}
.blog .blog-single-top i{
	color: #fff;
	font-size: 30px;
}
.blog .blog-featured-post{
	margin-bottom: 60px;
}
.blog .blog-featured-post .blog-featured-img{
	height:310px;
	width: calc(100% + 40px);
	margin-left: -20px;
	object-fit: cover;
	margin-bottom:15px;
	margin-top: -20px;
}
.blog .blog-featured-post .blog-featured-date{
	color: #483A3A;
	font-size: 14px;
	margin-bottom:15px;
}
.blog .blog-featured-post .blog-featured-title{
	color: #483A3A;
	font-size: 30px;
	font-weight: bold;
	line-height: 1.2;
	position:relative;
	margin:0;
	margin-bottom: 40px;
}
.blog .blog-featured-post .blog-featured-subtitle{
	color: #0AA09F;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	margin-bottom: 5px;
}
.blog .blog-featured-post .blog-featured-title:after{
	content:'';
	position:absolute;
	left:0;
	bottom:-15px;
	height: 3px;
	width: 20px;
	background: #483A3A;
}
.blog .blog-featured-post .blog-featured-desc{
	color: #483A3A;
	line-height: 1.2;
}
.blog .blog-featured-post .blog-featured-desc h4{
	font-size: 20px;
	font-weight: 500;
	margin-bottom:15px;
}
.blog .blog-featured-post .blog-featured-desc h3{
	font-size: 24px;
	font-weight: bold;
	margin-bottom:15px;
}
.blog .blog-featured-post .blog-featured-desc p{
	font-size: 18px;
	margin-bottom:15px;
}
.blog .blog-featured-post .blog-featured-desc a{
	color: #0AA09F;
}
.blog .blog-featured-post .blog-featured-desc .blog-desc-img{
	height:250px;
	width: calc(100% + 40px);
	margin-left: -20px;
	object-fit: cover;
	margin-bottom:15px;
}
.blog .blog-featured-post .blog-featured-author{
	display:flex;
	align-items: center;
	justify-content: flex-start;
	margin-top:40px;
}
.blog .blog-featured-post .blog-featured-author .blog-featured-author-icon{
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #0AA09F;
	display:flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 100;
	font-size: 18px;
	margin-right: 20px;
}
.blog .blog-featured-post .blog-featured-author p{
	margin:0;
	font-size: 14px;
}
.blog .blog-featured-post .blog-featured-author a{
	color: #0AA09F;
	font-weight: bold;
}
.blog .blog-other-posts{
	display:flex;
	align-items: center;
	justify-content: center;
}
.blog .blog-other-posts .blog-other-post-1{
	border: 1px solid #CBC0C0;
	display:flex;
	align-items: center;
	margin-right:10px;
	padding: 10px;
	border-radius: 4px;
}
.blog .blog-other-posts .blog-other-post-2{
	border: 1px solid #CBC0C0;
	display:flex;
	align-items: center;
	margin-left:10px;
	padding: 10px;
	border-radius: 4px;
}
.blog .blog-other-posts .blog-other-post-1 i{
	margin-left: -10px;
}
.blog .blog-other-posts .blog-other-post-2 i{
	margin-right: -10px;
}
.blog .blog-other-posts i{
	color: #B1A1A1;
	font-size: 26px;
}
.blog .blog-other-posts p{
	margin:0;
	font-size: 14px;
	color: #483A3A;
}
.blog hr{
	margin: 40px 0;
}
.blog-comments{
	position: relative;
	margin-top: 60px;
}
.blog-comments:before{
	content:'';
	position:absolute;
	left:0;
	top:-15px;
	height: 3px;
	width: 20px;
	background: #483A3A;
}
.blog .blog-comment{
	display:flex;
	margin-top: 20px;
}
.blog .blog-comment:first-child{
	margin-top:0;
}
.blog .blog-comments .blog-comment-img{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 20px;
}
.blog .blog-comments .blog-comment-right{
	width: calc(100% - 70px);
	border-bottom: 1px solid #CBC0C0;
}
.blog .blog-comments .blog-comment:last-child .blog-comment-right{
	border-bottom: 0 !important;
}
.blog .blog-comments .blog-comment-name{
	color: #0AA09F;
	font-size: 14px;
	font-weight: bold;
}
.blog .blog-comments .blog-comment-desc{
	font-size: 14px;
	color: #483A3A;
	font-weight: 100;
	margin-top: 10px;
}
.blog .blog-comments .btn-div-comment{
	height: 50px;
	display:flex;
	align-items:center;
	justify-content: flex-start;
}
.blog .blog-comments .btn-div-comment a{
	color: #0AA09F;
	font-weight: bold;
	margin: 0 5px;
	font-size: 14px;
}
.blog .blog-comments .btn-div-comment a:first-child{
	margin-left: 0;
}
.blog .blog-comments .btn-div-comment a:last-child{
	margin-right: 0;
}
.blog .blog-share-div{
	display:flex;
	align-items: center;
	margin-bottom: 20px;
	margin-top: 40px;
}
.blog .blog-share-div p{
	margin:0;
	color: #483A3A;
	font-size: 14px;
	font-weight: 100;
}
.blog .blog-share-div div{
	margin-left: auto;
}
.blog .blog-share-div i{
	font-size: 30px;
	margin: 0 5px;
}
.blog .blog-share-div i:last-child{
	margin:0;
}
.blog .blog-single{
	padding:0;
}
.blog .blog-single .blog-img{
	height: 106px;
	object-fit: cover;
	border-radius: 5px;
}
.blog .blog-single .blog-date{
	color: #483A3A;
	font-size:12px;
	margin: 10px 0;
	line-height: 1.2;
}
.blog .blog-single .blog-title{
	color: #483A3A;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.2;
	margin:0;
}
.blog .blog-single .blog-subtitle{
	color: #0AA09F;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.2;
	margin:0;
}
.blog .blog-single .blog-author{
	color: #483A3A;
	font-size: 12px;
	margin-top: 15px;
	line-height: 1.2;
}
.blog .blog-single .btn-div{
	margin:20px 0;
	width:100%;
	text-align:center;
}
.blog .comment-new-message{
	width:100%;
	border:0;
	border-bottom: 1px solid #CBC0C0;
	resize:none;
	font-size: 18px;
	color: #483A3A;
}
.blog .comment-new-message:focus{
	outline:0;
}
.blog .btn-div-2{
	margin:20px 0;
	width:100%;
}
.blog .blog-zone{
	padding: 20px 0;
	padding-bottom:0;
}
.blog .blog-zone .zones-scroll{
	overflow-x: auto;
    margin-right: -20px;
	padding-bottom:20px;
}
.blog .blog-zone .zones-container{
	display: flex;
    width: fit-content;
}
.blog .blog-zone .zone-item{
	width:50vw;
	padding-right: 20px;
}
.blog .blog-zone .zone-img-container{
	position:relative;
}
.blog .blog-zone .zone-img-container p{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
	text-align: center;
    letter-spacing: 1px;
	margin:0;
}
.blog .blog-zone .zone-img{
	height:120px;
	object-fit:cover;
	border-radius:5px;
}
.blog .blog-zone .zone-district{
	margin: 0;
    margin-top: 20px;
    color: #483A3A;
    font-size: 16px;
    line-height: 1.3;
}
.blog .blog-zone .zone-desc{
	margin: 0;
    font-weight: bold;
    color: #483A3A;
    font-size: 16px;
    line-height: 1.3;
}
.blog .add-home{
	position:relative;
	padding:20px 0;
}
.blog .add-home .add-home-img{
	height: 200px;
    object-fit: cover;
    border-radius: 5px;
}
.blog .add-home .add-home-container{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:calc(100% - 40px);
	background: rgba(0, 0, 0, 0.5);
	margin:20px 0;
	padding:20px;
	border-radius:5px;
}
.blog .add-home .add-home-container h3{
	font-weight:bold;
	font-size:20px;
	color:#fff;
	margin-bottom:5px;
	line-height:1.2;
}
.blog .add-home .add-home-container p{
	font-size:14px;
	color:#fff;
	line-height:1.2;
}
.blog .add-home .add-home-container a{
	position:absolute;
	bottom:20px;
}
.blog .blog-single .service-name{
	margin:0;
	margin-top:15px;
	font-weight: bold;
	color: #483A3A;
	font-size: 14px;
	line-height: 1.3;
}
@media only screen and (min-width: 600px) {
	.blog.blog-s{
		padding:0;
	}
	.blog.blog-s .blog-featured-post .blog-featured-img{
		height:660px;
	}
	.blog.blog-s .bg-white-transform{
		top: 600px;
	}
	.blog.blog-s .blog-featured-post .blog-featured-date{
		position: absolute;
		top: 620px;
		z-index: 999;
	}
	.blog.blog-s .blog-s-content{
		padding-top: 50px;
	}
	.blog.blog-s .blog-featured-post .blog-featured-title{
		font-size: 50px;
		line-height:1;
		margin-top: 20px;
	}
	.blog.blog-s .blog-featured-post .blog-featured-title:after{
		bottom: -40px;
	}
	.blog.blog-s .blog-share-div{
		margin-top: 100px;
	}
}